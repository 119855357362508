import http from '@/utils/request'
// 提交基础信息
export const updateInviteProgress = (data) => http.post("/trade/tradeInvite/updateInviteProgress", data)
//查询邀约进度
export const getInviteProgress = (data) => http.post("/trade/tradeInvite/getInviteProgress", data)
//发起邀约
export const initiateInvite = (data) => http.post("/trade/tradeInvite/initiateInvite", data)
// 列表
export const listTradeInvite = (data) => http.post("/trade/tradeInvite/listTradeInvite", data)
// 邀约确认
export const confirmInvite = (data) => http.post("/trade/tradeInvite/confirmInvite", data)
// 删除邀约商品 
export const deleteInviteGoods = (data) => http.post("/trade/tradeInvite/deleteInviteGoods", data)

